<template>
  <div>
    <PageLoader :storage="appLoading" />
    <v-layout
      wrap
      justify-center
      py-12
      px-sm-0
      px-md-6
      px-lg-16
      :style="'background-color:' + headerData.color"
    >
      <v-flex xs12 sm10 px-3 px-sm-0>
        <v-card
          flat
          color="transparent"
          :height="
            $vuetify.breakpoint.name == 'xs'
              ? '400px'
              : $vuetify.breakpoint.name == 'sm'
              ? '340px'
              : $vuetify.breakpoint.name == 'md'
              ? '300px'
              : $vuetify.breakpoint.name == 'lg'
              ? '350px'
              : '450px'
          "
        >
          <v-layout wrap>
            <v-flex xs12 sm9 md7 lg6 pr-5>
              <v-card color="transparent" flat>
                <v-layout wrap py-5>
                  <v-flex xs12 align-self-center text-left>
                    <span class="bantitle12">Products & Services</span>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left>
                    <span
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xs'
                            ? '26px'
                            : $vuetify.breakpoint.name == 'sm'
                            ? '40px'
                            : $vuetify.breakpoint.name == 'md'
                            ? '30px'
                            : $vuetify.breakpoint.name == 'lg'
                            ? '40px'
                            : '40px',
                      }"
                      class="bantitle13"
                    >
                      {{ headerData.name }}
                    </span>
                  </v-flex>

                  <v-flex xs12 py-2 align-self-center text-left>
                    <span
                      style="text-align: justify; color: white !important"
                      v-html="headerData.description"
                      class="bantitle14"
                    >
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-8 align-self-center text-left>
                    <v-btn to="/contact" tile color="#000">
                      <span
                        class="text-capitalize"
                        style="color: #fff"
                        :style="{
                          'font-size':
                            $vuetify.breakpoint.name == 'xs'
                              ? '10px'
                              : $vuetify.breakpoint.name == 'sm'
                              ? '12px'
                              : $vuetify.breakpoint.name == 'md'
                              ? '14px'
                              : $vuetify.breakpoint.name == 'lg'
                              ? '14px'
                              : '14px',
                        }"
                      >
                        Have a project in mind? Chat with us<v-icon
                          class="px-3"
                          size="16"
                          ti
                          color="#FCBA4C"
                          >mdi-arrow-right</v-icon
                        >
                      </span>
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs12 md8 lg7 align-self-center text-left>
            <span class="bantitle15">
              With numerous successes and products to its name, the Conservation
              Technology Division is in the forefront of the Conservation
              Technology Sector.
            </span>
          </v-flex> -->
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              sm3
              md5
              lg6
              pt-0
              pt-md-0
              pt-sm-5
              class="hidden-xs-only"
              align-self-center 
            >
              <v-img
                style="box-shadow: 0px 0px 0px #0000001d"
                :height="
                  $vuetify.breakpoint.name == 'xl'
                    ? '655px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '518px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '390px'
                    : 'auto'
                "
                contain
                :src="mediaURLold + headerData.image"
              ></v-img>

              <!-- :src="webpSupported && item.image.split('.').pop() !== 'svg'? mediaURL + headerData.image : mediaURLold + headerData.image" -->
              <!-- src="../../assets/Images/products.png" -->
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <!-- </v-layout>
    <v-layout wrap pa-2 pa-sm-16 pa-md-6 pa-lg-16 style="background-color: #f8f8f8"> -->
    </v-layout>

    <v-layout
      wrap
      pa-2
      py-8
      pa-sm-16
      pa-md-6
      pa-lg-16
      style="background-color: #f2f2f2"
    >
      <!-- <v-flex xs4 sm2 md2 lg1 pl-2 pb-8 text-left>
        <span style="border-bottom: 3px solid #b88726">Our Projects</span>
      </v-flex> -->

      <v-flex
        v-if="subCategories && subCategories.length > 1"
        text-left
        pt-0
        pt-sm-12
        pb-4
      >
        <v-tabs
          v-model="subCategoryIndex"
          :value="val"
          background-color="transparent"
          :color="headerData.color"
          grow
        >
          <v-tab @click="subCategory = null">
            <span>All</span>
          </v-tab>
          <v-tab
            v-for="item in subCategories"
            @click="subCategory = item._id"
            :key="item._id"
          >
            <span>{{ item.name }}</span>
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex xs12 v-if="works.length > 0" align-self-center>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            px-md-3
            px-2
            py-4
            sm6
            md4
            lg3
            v-for="(item, i) in works"
            :key="i"
          >
            <v-card
              tile
              :height="
                $vuetify.breakpoint.name == 'xs'
                  ? '450px'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '400px'
                  : $vuetify.breakpoint.name == 'md'
                  ? '400px'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '430px'
                  : '600px'
              "
            >
              <v-layout wrap>
                <v-flex xs12 md12 lg12 xl12 align-self-start text-left>
                  <v-img
                    cover
                    width="100%"
                    :height="$vuetify.breakpoint.name=='xl'?'400px':'200px'"
                    :src="webpSupported && item.image.split('.').pop() !== 'svg'? mediaURL + item.image : mediaURLold + item.image"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
              </v-layout>
              <v-layout wrap pa-4 pa-md-2 pa-lg-4 pa-xl-6>
                <v-flex
                  style="min-height: 60px !important"
                  xs12
                  lg11
                  py-2
                  align-self-center
                >
                  <span class="bantitle16">{{ item.name }}</span>
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-layout wrap>
                    <v-flex xs10 align-self-center>
                      <span style="text-align: justify" class="bantitle17">{{
                        item.title.slice(0, 190)
                      }}</span>
                    </v-flex>
                    <v-flex
                      @click="
                        $router.push({
                          path: 'products/product',
                          query: {
                            id: item._id,
                            name: item.name,
                          },
                        })
                      "
                      style="cursor: pointer"
                      align-self-end
                      text-right
                    >
                      <v-icon color="#C0831E">mdi-arrow-right</v-icon>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-5 v-else align-self-center>
        <span span class="bantitle15">Projects are currently unavailable.</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      headerData: {},
      works: [],
      appLoading: false,
      ServerError: false,
      msg: null,
      subCategories: [],
      subCategoryIndex: 0,
      subCategory: null,
      webpSupported: true,
      val:0,
    };
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then(r => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

    })();
  },
  beforeMount() {
    this.getData();
    this.getCategoryData();
    this.getSubCategoryData();
  },
  watch: {
    subCategory() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/getlist",
        params: {
          category: this.$route.query.category,
          // client: this.$route.query.client,
          subCategory: this.subCategory,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.works = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/category/get",
        params: {
          id: this.$route.query.category,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.headerData = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/subcategory/getlist",
        params: {
          category: this.$route.query.category,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.subCategories = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeRoute(item) {
      this.$router.push({
        path: "/Products/",
        query: {
          category: this.$route.query.category,
          subCategory:
            this.$route.query.subCategory != item._id ? item._id : null,
        },
      });
    },
  },
};
</script>
<style scoped>
.prodGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #f5a624 0%, #ffc837 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
</style>