<template>
  <div
    :style="
      $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
        ? 'padding-top: 0px'
        : 'padding-top: 50px'
    "
  >
    <v-layout wrap>
      <v-flex xs12>
        <Products />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Products from "@/components/Products/Products";
export default {
  components: {
    Products,
  },
};
</script>