var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),_c('v-layout',{style:('background-color:' + _vm.headerData.color),attrs:{"wrap":"","justify-center":"","py-12":"","px-sm-0":"","px-md-6":"","px-lg-16":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","px-3":"","px-sm-0":""}},[_c('v-card',{attrs:{"flat":"","color":"transparent","height":_vm.$vuetify.breakpoint.name == 'xs'
            ? '400px'
            : _vm.$vuetify.breakpoint.name == 'sm'
            ? '340px'
            : _vm.$vuetify.breakpoint.name == 'md'
            ? '300px'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '350px'
            : '450px'}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm9":"","md7":"","lg6":"","pr-5":""}},[_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-layout',{attrs:{"wrap":"","py-5":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-left":""}},[_c('span',{staticClass:"bantitle12"},[_vm._v("Products & Services")])]),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-left":""}},[_c('span',{staticClass:"bantitle13",style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xs'
                          ? '26px'
                          : _vm.$vuetify.breakpoint.name == 'sm'
                          ? '40px'
                          : _vm.$vuetify.breakpoint.name == 'md'
                          ? '30px'
                          : _vm.$vuetify.breakpoint.name == 'lg'
                          ? '40px'
                          : '40px',
                    })},[_vm._v(" "+_vm._s(_vm.headerData.name)+" ")])]),_c('v-flex',{attrs:{"xs12":"","py-2":"","align-self-center":"","text-left":""}},[_c('span',{staticClass:"bantitle14",staticStyle:{"text-align":"justify","color":"white !important"},domProps:{"innerHTML":_vm._s(_vm.headerData.description)}})]),_c('v-flex',{attrs:{"xs12":"","pt-8":"","align-self-center":"","text-left":""}},[_c('v-btn',{attrs:{"to":"/contact","tile":"","color":"#000"}},[_c('span',{staticClass:"text-capitalize",staticStyle:{"color":"#fff"},style:({
                        'font-size':
                          _vm.$vuetify.breakpoint.name == 'xs'
                            ? '10px'
                            : _vm.$vuetify.breakpoint.name == 'sm'
                            ? '12px'
                            : _vm.$vuetify.breakpoint.name == 'md'
                            ? '14px'
                            : _vm.$vuetify.breakpoint.name == 'lg'
                            ? '14px'
                            : '14px',
                      })},[_vm._v(" Have a project in mind? Chat with us"),_c('v-icon',{staticClass:"px-3",attrs:{"size":"16","ti":"","color":"#FCBA4C"}},[_vm._v("mdi-arrow-right")])],1)])],1)],1)],1)],1),_c('v-flex',{staticClass:"hidden-xs-only",attrs:{"xs12":"","sm3":"","md5":"","lg6":"","pt-0":"","pt-md-0":"","pt-sm-5":"","align-self-center":""}},[_c('v-img',{staticStyle:{"box-shadow":"0px 0px 0px #0000001d"},attrs:{"height":_vm.$vuetify.breakpoint.name == 'xl'
                  ? '655px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '518px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '390px'
                  : 'auto',"contain":"","src":_vm.mediaURLold + _vm.headerData.image}})],1)],1)],1)],1)],1),_c('v-layout',{staticStyle:{"background-color":"#f2f2f2"},attrs:{"wrap":"","pa-2":"","py-8":"","pa-sm-16":"","pa-md-6":"","pa-lg-16":""}},[(_vm.subCategories && _vm.subCategories.length > 1)?_c('v-flex',{attrs:{"text-left":"","pt-0":"","pt-sm-12":"","pb-4":""}},[_c('v-tabs',{attrs:{"value":_vm.val,"background-color":"transparent","color":_vm.headerData.color,"grow":""},model:{value:(_vm.subCategoryIndex),callback:function ($$v) {_vm.subCategoryIndex=$$v},expression:"subCategoryIndex"}},[_c('v-tab',{on:{"click":function($event){_vm.subCategory = null}}},[_c('span',[_vm._v("All")])]),_vm._l((_vm.subCategories),function(item){return _c('v-tab',{key:item._id,on:{"click":function($event){_vm.subCategory = item._id}}},[_c('span',[_vm._v(_vm._s(item.name))])])})],2)],1):_vm._e(),(_vm.works.length > 0)?_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},_vm._l((_vm.works),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","px-md-3":"","px-2":"","py-4":"","sm6":"","md4":"","lg3":""}},[_c('v-card',{attrs:{"tile":"","height":_vm.$vuetify.breakpoint.name == 'xs'
                ? '450px'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '400px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '400px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '430px'
                : '600px'}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","lg12":"","xl12":"","align-self-start":"","text-left":""}},[_c('v-img',{attrs:{"cover":"","width":"100%","height":_vm.$vuetify.breakpoint.name=='xl'?'400px':'200px',"src":_vm.webpSupported && item.image.split('.').pop() !== 'svg'? _vm.mediaURL + item.image : _vm.mediaURLold + item.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1)],1),_c('v-layout',{attrs:{"wrap":"","pa-4":"","pa-md-2":"","pa-lg-4":"","pa-xl-6":""}},[_c('v-flex',{staticStyle:{"min-height":"60px !important"},attrs:{"xs12":"","lg11":"","py-2":"","align-self-center":""}},[_c('span',{staticClass:"bantitle16"},[_vm._v(_vm._s(item.name))])]),_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs10":"","align-self-center":""}},[_c('span',{staticClass:"bantitle17",staticStyle:{"text-align":"justify"}},[_vm._v(_vm._s(item.title.slice(0, 190)))])]),_c('v-flex',{staticStyle:{"cursor":"pointer"},attrs:{"align-self-end":"","text-right":""},on:{"click":function($event){return _vm.$router.push({
                        path: 'products/product',
                        query: {
                          id: item._id,
                          name: item.name,
                        },
                      })}}},[_c('v-icon',{attrs:{"color":"#C0831E"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)],1)}),1)],1):_c('v-flex',{attrs:{"xs12":"","py-5":"","align-self-center":""}},[_c('span',{staticClass:"bantitle15",attrs:{"span":""}},[_vm._v("Projects are currently unavailable.")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }